import StoreButtonComponent from '@pretto/bricks/components/buttons/StoreButton'

import PropTypes from 'prop-types'

import * as S from '../styles'

const StoreButton = ({ type }) => (
  <S.Block align="center">
    <StoreButtonComponent slug={type} />
  </S.Block>
)

StoreButton.propTypes = {
  type: PropTypes.oneOf(['GooglePlayStore', 'AppStore']).isRequired,
}

export default StoreButton
